import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import "./section-title.scss"

const SectionTitle = ({ title, className }) => (
  <div
    className={classnames(
      "section-title",
      "title",
      "is-family-secondary",
      "has-text-weight-light",
      "is-capitalized",
      "is-size-3-desktop",
      "is-size-3-mobile",
      className
    )}
  >
    {title}
  </div>
)

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default SectionTitle