import React from "react"

const Footer = () => (
  <footer className="footer" style={{
    borderTop: `2px solid #FAB51C`,
    borderBottom: `2px solid #FAB51C`,
  }}>
    <div className="content has-text-centered">
      <p>
        © {new Date().getFullYear()}, Mopo Kobanda Avocats.
      </p>
    </div>
  </footer>
)

export default Footer