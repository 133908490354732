import PropTypes from "prop-types"
import React from "react"
import logo from "./logo-MK-v1.2.png"
import { Link } from "gatsby"

import "./header.scss"

const Header = ({ siteTitle }) => (
  <header className="header-wrapper">
    <nav className="navbar">
      <div className="container">
        <div className="navbar-brand">
          <a className="navbar-item" href="http://google.fr">
            <img
              src={logo}
              alt="Logo"
              width="80"
              style={{
                maxHeight: "100px",
                marginTop: "5px",
                marginBottom: "5px",
              }}
            />
          </a>
          <a
            role="button"
            className="navbar-burger burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
            href="#accueil"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div className="navbar-end header-navbar-end">
          <Link
            className="navbar-item is-family-primary header-navbar-link is-size-4"
            to="/"
          >
            Accueil
          </Link>
          <Link
            className="navbar-item is-family-primary header-navbar-link is-size-4 is-capitalized"
            to="/equipe"
          >
            équipe
          </Link>
          <Link
            className="navbar-item is-family-primary header-navbar-link is-size-4 "
            to="/competences"
          >
            Compétences
          </Link>
          <Link
            className="navbar-item is-family-primary header-navbar-link is-size-4"
            to="/honoraires"
          >
            Honoraires
          </Link>
          <Link
            className="navbar-item is-family-primary header-navbar-link is-size-4"
            to="/contact"
          >
            Contact
          </Link>
        </div>
      </div>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
