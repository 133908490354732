import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import "./page-title.scss"

const PageTitle = ({ title, className }) => (
  <div className="page-title-container">
    <div
      className={classnames(
        "title",
        "page-title",
        "is-family-secondary",
        "is-capitalized",
        "has-text-weight-light",
        "is-size-2-desktop",
        "is-size-3-mobile",
        className
      )}
    >
      {title}
    </div>
    <div className="navbar-divider title-underbar" />
  </div>
)

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
}
export default PageTitle
